<template>
  <div>
    <Navlid :list="navlist"></Navlid>

    <div class="detail-box public-box" v-if="detail">
      <div class="detail-left">
        <div class="title">基础信息</div>
        <div class="userinfo">
          <div class="user">
            <img class="head" v-img :src="detail.merchants_logo" alt="" />
            <div>{{ detail.merchants_stall_name }}</div>
          </div>
          <div class="item">
            <div>
              <span>合同文件:</span>
              <span class="status" style="marginleft: 5px" @click="checktpl(detail.contract_tpl)">
                {{ detail.contract_type == 20 ? "线下已签" : "线上签订" }}
                <img class="icon" src="../../../assets/img/contractdetail/offline.png" alt />
              </span>
            </div>
            <div>
              <span>合同开始时间:</span>
              <span style="marginleft: 5px">{{ detail.contract_start }}</span>
            </div>
          </div>
          <div class="item">
            <div>
              <span>合同编号:</span>
              <span style="marginleft: 5px">{{ detail.contract_no }}</span>
            </div>
            <div>
              <span>合同到期时间:</span>
              <span style="marginleft: 5px">{{ detail.contract_end }}</span>
            </div>
          </div>
        </div>

        <!-- shop -->
        <div class="shoplist">
          <div class="listbox">
            <div class="list" v-for="(item, index) in detail.contract_fee_setting" :key="index">
              <div class="listLeft">
                <img src="../../../assets/img/contractdetail/shop.png" alt="" />
              </div>
              <div class="right">
                <div class="listItem" v-for="(list, j) in item" :key="j">
                  <div class="content" style="width: 160px" v-if="j == 0">
                    <span>商铺号：{{ list.merchants_stall_name }}</span>
                  </div>
                  <div class="content" v-else>
                    <span>{{ list.contract_fee_name }}</span>
                    <span class="num">￥{{ list.pay_price }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="count">
            <div>
              <span>租期内总金额:</span>
              <span>￥{{ detail.total_price || 0 }}</span>
            </div>
            <div>
              <span>月总金额:</span>
              <span>￥{{ detail.month_price || 0 }}</span>
            </div>
          </div>
        </div>

        <div class="moneytype">
          <div class="item" v-for="(item, index) in detail.dcList" :key="index">
            <span class="name">{{ item.contract_fee_name }}</span>
            <span class="bold">￥{{ item.pay_price }}</span>
            <span class="margin">缴费时间:</span>
            <span>{{ item.pay_time }}</span>
          </div>
          <div class="item" v-if="detail.contract_manage">
            <span class="name">经营范围:</span>
            <span>{{ detail.contract_manage }}</span>
          </div>
          <div class="item" v-if="detail.diff_month">
            <span class="name">免租期:</span>
            <span class="margin" style="color: black">{{ detail.diff_month }}个月</span>
            <span class="margin">免租开始时间:</span>
            <span class="margin">{{ detail.contract_fee_start }}</span>
            <span class="margin">免租结束时间:</span>
            <span>{{ detail.contract_fee_end }}</span>
          </div>
          <div class="item" v-if="detail.contract_overdue">
            <span class="name">违约金:</span>
            <span style="color: black">
              逾期<span style="color: #e90000; font-weight: bold">{{
                detail.contract_overdue
              }}</span>
              天,按照逾期支付费用的<span style="color: #e90000; font-weight: bold">
                {{ detail.contract_overdue_value }}%</span>
              支付违约金
            </span>
          </div>
          <div class="item increase" v-if="detail &&
            detail.contract_increasing &&
            detail.contract_increasing.length > 0
            ">
            <!-- <table class="customTable">
              <tr>
                <th>递增年</th>
                <th>递增类型</th>
                <th>递增率</th>
                <th>递增费用</th>
                <th v-for="(item, index) in detail.contract_increasing[0].fees_list" :key="index">
                  每月{{ item.name }}
                </th>
                <th>月总金额</th>
              </tr>
              <tr v-for="(item, index) in detail.contract_increasing" :key="index">
                <td>第{{ index + 1 }}年</td>
                <td>{{ item.change == 2 ? '固定金额' : '百分比' }}</td>
                <td>{{ item.contract_increasing }}{{ item.change == 2 ? '元' : '%' }}</td>
                <td ref="feestd">
                  {{ item.feeName.length == 0 ? "/" : item.feeName.join(",") }}
                </td>
                <td v-for="(list, j) in item.fees_list" :key="j">
                  {{ list.pay_price }}
                </td>
                <td>{{ item.month_pay_price }}</td>
              </tr>
            </table> -->
            <el-table :data="detail.contract_increasing_temp" :span-method="objectSpanMethod" :header-cell-style="{
              'text-align': 'center',
              background: 'rgb(245, 245, 245)',
              height: '37px',
            }" :cell-style="{ 'text-align': 'center', height: '37px' }" height="80%">
              <el-table-column label="递增年">
                <template slot-scope="scope">
                  第 {{ Number(scope.row.index) }}年度
                </template>
              </el-table-column>
              <el-table-column label="缴费项" prop="item.name"></el-table-column>
              <el-table-column label="递增类型" prop="pay_time">
                <template slot-scope="scope">
                  {{ scope.row.item.change == 2 ? "固定金额" : "百分比" }}
                </template>
              </el-table-column>
              <el-table-column label="递增费用" prop="pay_price">
                <template slot-scope="scope">
                  {{ scope.row.item.contract_increasing
                  }}{{ scope.row.item.change == 2 ? "元" : "%" }}
                </template>
              </el-table-column>
              <el-table-column label="月总金额" prop="item.pay_price"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="detail-right">
        <div class="spxx box">
          <div class="title">审批信息</div>
          <el-table :data="detail.approvalList" :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
            height: '37px',
          }" :cell-style="{ 'text-align': 'center', height: '37px' }">
            <el-table-column label="申请人" prop="worker_name"></el-table-column>
            <el-table-column label="审批" prop="agree_worker_name"></el-table-column>
            <el-table-column label="审批状态" prop="contract_status_name">
              <template slot-scope="{ row }">
                <span>{{
                  row.contract_status == 10
                  ? "同意"
                  : row.contract_is_agree == 10
                    ? "同意"
                    : row.contract_user_is_agree == 10
                      ? "同意"
                      : row.contract_status_name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" show-overflow-tooltip prop="contract_desc">
            </el-table-column>
            <el-table-column label="提交时间" prop="create_at"></el-table-column>
          </el-table>
        </div>
        <div class="ydjfxx box">
          <div class="title">约定缴费信息</div>
          <el-table :data="detail.payList" :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
            height: '37px',
          }" :cell-style="{ 'text-align': 'center', height: '37px' }" height="80%">
            <el-table-column label="费用类型" prop="contract_fee_name"></el-table-column>
            <el-table-column label="缴费时间" prop="pay_time">
              <template slot-scope="{ row }">
                {{ formatTimeHms(row.pay_time) }}
              </template>
            </el-table-column>
            <el-table-column label="缴费周期" prop="pay_time">
              <template #default="{ row }">
                <div>{{ row.property_time }} ~ {{ row.property_end_time }}</div>
              </template>
            </el-table-column>
            <el-table-column label="应缴费金额" prop="pay_price">
              <template #default="{ row }">
                <div v-if="row.is_derate">
                  {{
                    Number(
                      parseFloat(
                        Number(row.pay_price) - Number(row.derate_pay)
                      ).toFixed(2)
                    )
                  }}
                </div>
                <div v-else>{{ Number(row.pay_price) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="减免" prop="is_derate">
              <template slot-scope="{ row }">
                {{ row.is_derate ? "是" : "否" }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="ydjfxx box" v-if="detail.contract_status == 1 && detail.contract_agree">
          <div class="title">终止信息</div>
          <div class="termination">
            <div class="boxList">
              <span>提交人：</span>
              <span>{{ detail.worker_name }}</span>
            </div>
            <div class="boxList">
              <span>提交时间：</span>
              <span>{{ detail.contract_agree.agree_time }}</span>
            </div>
            <div class="boxList">
              <span>原因：</span>
              <span>{{ detail.contract_agree.over_reason }}</span>
            </div>
            <div class="boxList">
              <span>凭证：</span>
              <MyButton title="查看" @click="checkvoucher"></MyButton>
            </div>
            <div class="boxList">
              <span>审批人：</span>
              <span>{{ detail.agree_worker_name }}</span>
            </div>
            <div class="boxList">
              <span>审批状态：</span>
              <span v-if="detail.contract_agree.over_is_agree == 10">待审核</span>
              <span v-if="detail.contract_agree.over_is_agree == 20">同意</span>
              <span v-if="detail.contract_agree.over_is_agree == 30">拒绝</span>
            </div>
            <div class="boxList">
              <span>备注：</span>
              <span>{{ detail.contract_agree.over_remark }}</span>
            </div>
          </div>
        </div>
        <div class="htgl box" v-if="detail.contract_status == 10">
          <div class="title">
            <span>合同管理</span>
            <span class="send" v-if="detail.contract_renewal == 0"
              @click="$common.hasRole(94663) && seedNotification()">发送续签通知</span>
          </div>
          <div class="item" v-if="detail.contract_info">
            <MyButton>已完成续签</MyButton>
            <MyButton :accessId="21508" @click="clickTerminate" v-if="detail.contract_status == 10" class="btn">终止合同
            </MyButton>
          </div>
          <div class="item" v-else>
            <MyButton :accessId="91240" @click="renew" class="btn" v-if="detail.contract_renewal == 30">续签合同</MyButton>
            <!-- <MyButton @click="goUrl('')" class="btn">变更合同</MyButton> -->
            <MyButton :accessId="21508" @click="clickTerminate" v-if="detail.contract_status == 10" class="btn">终止合同
            </MyButton>
          </div>
        </div>
        <!-- fk -->
        <div class="fk box" v-if="detail.contract_status != 1">
          <div class="title">
            <span>消息反馈</span>
            <!-- <span class="time">续签消息发送时间：{{detail.update_at}}</span> -->
          </div>
          <div class="list" v-if="detail.property_log">
            <div class="listitem" v-for="(item, index) in detail.property_log" :key="index">
              <img src="../../../assets/img/contractdetail/ty.png" alt />
              <span class="main">{{ item.create_at }}</span>
              <span class="tips">{{ item.property_content }}</span>
              <!-- <span class="godetail" @click="goUrl">去处理</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stopmessage" v-if="ctype && detail && detail.bill_list">
      <div class="title">终止前缴费信息</div>
      <el-table :data="detail.bill_list" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
        height: '50px'
      }" :cell-style="{ 'text-align': 'center', height: '50px' }">
        <el-table-column label="合同编号" prop="contact_name">
          <template #default>
            <span>
              {{ detail.contract_no }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="费用类型" prop="bill_name"></el-table-column>
        <el-table-column label="合同缴费时间" prop="confirm_time"></el-table-column>
        <el-table-column label="合同缴费金额" prop="key_total_price"></el-table-column>
        <el-table-column label="实缴金额" prop="real_pay_amount"></el-table-column>
        <el-table-column label="余额抵扣" prop="balance_price"></el-table-column>
        <el-table-column label="减免金额" prop="coupon_price"></el-table-column>
        <el-table-column label="优惠金额" prop="give_discount"></el-table-column>
        <el-table-column label="抹零金额" prop="zero_price"></el-table-column>
        <el-table-column label="剩余待缴金额" prop="key_pay_price"></el-table-column>
        <el-table-column label="费用情况">
          <template slot-scope="scope">
            <span v-if="scope.row.bill_status == 10">已结清</span>
            <span v-else>{{
              parseFloat(scope.row.key_update_price) > 0 ? "已缴费" : "未缴费"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="stopmessage">
      <div class="title">账单信息</div>
      <el-table :data="billList" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
        height: '50px'
      }" :cell-style="{ 'text-align': 'center', height: '50px' }">
        <el-table-column label="流水号" prop="bill_no" show-overflow-tooltip width="200px"></el-table-column>
        <el-table-column label="收费项名称" prop="contract_fee_name"></el-table-column>
        <el-table-column label="缴费时间" prop="pay_time">
          <template #default="{ row }">
            <div>{{ $common.formatTimeHms(row.pay_time) }}</div>
          </template>
        </el-table-column>
        <el-table-column width="200" label="缴费周期" prop="property_time">
          <template #default="{ row }">
            <div>{{ row.property_time }} ~ {{ row.property_end_time }}</div>
          </template>
        </el-table-column>
        <el-table-column label="优惠金额" prop="give_discount"></el-table-column>
        <el-table-column label="减免金额" prop="coupon_price"></el-table-column>
        <el-table-column label="余额抵扣金额" prop="balance_price"></el-table-column>
        <el-table-column label="抹零金额" prop="zero_price"></el-table-column>
        <el-table-column label="实缴金额" prop="real_pay_amount"></el-table-column>
        <el-table-column label="剩余应缴金额" prop="key_pay_price"></el-table-column>
        <el-table-column label="总金额" prop="key_total_price"></el-table-column>
        <el-table-column label="缴费方式">
          <template #default="{ row }">
            <span>{{
              $chargeWay.find((item) => item.id == row.pay_type)
              ? $chargeWay.find((item) => item.id == row.pay_type).value
              : "/"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收费人" prop="bill_payee"></el-table-column>
      </el-table>
      <div class="apiPage" style="marginBottom: 0;">
        <el-pagination :total="billTotal" :current-page="billParams.pageNum" :page-size="billParams.pageSize"
          layout="total,prev,pager,next" next-text="下一页" prev-text="上一页" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
    <!-- 发送续签通知 -->
    <el-dialog title="发送续签通知" :visible.sync="renewVasible" @close="close" :close-on-click-modal="false">
      <el-input :rows="4" v-model="renewFormmessage.content" placeholder="" type="textarea"></el-input>
      <template slot="footer">
        <MyButton @click="renewVasible = false">取消</MyButton>
        <MyButton type="primary" @click="sendMessage" left>发送</MyButton>
      </template>
    </el-dialog>

    <el-dialog title="终止合同" :visible.sync="terminateContractVasible" @close="closeContract" :close-on-click-modal="false">
      <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
        <el-form-item label="原因">
          <el-input type="textarea" v-model="ruleForm.over_reason"></el-input>
        </el-form-item>
        <el-form-item label="终止时间" prop="contract_stop_time">
          <el-date-picker v-model="ruleForm.contract_stop_time" value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择终止时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="凭证">
          <el-upload action :on-change="handlePreview" :show-file-list="false" :auto-upload="false">
            <div class="imgBox" v-if="ruleForm.over_voucher.length > 0">
              <div class="imgList" v-for="(item, index) in ruleForm.over_voucher" :key="index">
                <i class="icon el-icon-circle-close" @click.stop="del(index)"></i>
                <img :src="imgUrl + item" alt="" />
              </div>
            </div>
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton @click="terminateContractVasible = false">取消</MyButton>
        <MyButton type="primary" @click="terminateContract" left>确认</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="arrearageVasible" :close-on-click-modal="false">
      <p style="color: #e90000; marginbottom: 15px">
        合同终止前请确认合同各项费用是否收齐，终止后将无法再进行收费操作!
      </p>
      <el-table :data="arrearageList"
        :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)', height: '37px' }"
        :cell-style="{ 'text-align': 'center', height: '37px' }">
        <!-- <el-table-column label="欠费金额" prop="key_pay_price"></el-table-column>
        <el-table-column label="余额" prop="balance"></el-table-column> -->
        <el-table-column :label="index" v-for="(item, index) in arrearageColumn" :key="index">
          <template>
            {{ item }}
          </template>
        </el-table-column>
      </el-table>
      <template slot="footer">
        <MyButton @click="arrearageVasible = false">取消</MyButton>
        <MyButton type="primary" @click="terminateContractVasible = true" left>继续</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="凭证" :visible.sync="vouchervisible" :close-on-click-modal="false">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="(item, index) in voucher" :key="index">
          <img class="voucher-img" v-img :src="$common.hashost(item)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>
<script>
import { number } from "echarts";
import { formatTimeHms } from "../../../plugin/common";

export default {
  data () {
    return {
      navlist: [
        {
          title: "合同列表",
          url: "/contract/contractlist",
        },
        {
          title: "合同详情",
        },
      ],
      id: "",
      detail: null,
      renewVasible: false,
      renewFormmessage: {
        content: "",
      },
      info: {},
      tablespr: [{}],
      terminateContractVasible: false,
      ruleForm: {
        over_reason: "",
        contract_stop_time: '',
        over_voucher: [],
      },
      rules: {
        contract_stop_time: [
          { required: true, message: '请选择合同终止时间', trigger: 'change' },
        ]
      },
      imgUrl: process.env.VUE_APP_IMG_URL,
      vouchervisible: false,
      voucher: [],
      ctype: null,
      fixedList: [],
      merchantInfo: null,
      arrearageVasible: false,
      arrearageList: [],
      arrearageColumn: [],
      billList: [],
      billParams: {
        pageNum: 1,
        pageSize: 10
      },
      billTotal: 0
    };
  },
  created () {
    this.id = this.$route.query.id;
    let mId = this.$route.query.mId;
    this.ctype = this.$route.query.type;
    if (this.ctype) {
      this.navlist[0] = { title: "历史合同", url: "/contract/contracthistory" };
      this.getMerchantInfo(mId);
      this.getFixedList(mId);
    }
    this.getDetail();
  },
  mounted () {
    this.$nextTick(() => {
      console.log(this.$refs.feestd);
    });
  },
  methods: {
    formatTimeHms,
    getBillList () {
      this.$request.HttpGet("/bill/list", {
        contract_id: this.id,
        merchants_id: this.detail.merchants_id,
        bill_status: 1,
        ...this.billParams
      }).then((res) => {
        this.billList = res.data.list;
        this.billTotal = res.data.total || 0;
      });
    },
    currentChange (page) {
      this.billParams.pageNum = page;
      this.getBillList();
    },
    clickTerminate () {
      this.$request.HttpPost("/contract_agree/stopContractMessage", {
        contract_id: this.id,
        merchants_id: this.detail.merchants_id,
      }).then((res) => {
        this.arrearageColumn = res.data || [];
        // const data = [{
        //   balance: res.data.balance,
        //   key_pay_price: res.data.key_pay_price,
        // }];
        this.arrearageList = [{}];
        this.arrearageVasible = true;
      });
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        if (row.rowspan) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        }
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },
    closeContract () {
      this.ruleForm = {
        over_reason: "",
        over_voucher: [],
      };
    },
    del (index) {
      this.ruleForm.coupon_voucher.splice(index, 1);
    },
    terminateContract () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$request.HttpPost("/contract/stopContract", {
            contract_id: this.id,
            ...this.ruleForm,
            over_voucher: JSON.stringify(this.ruleForm.over_voucher),
          }).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.$router.go(-1);
          }).finally(() => {
            this.terminateContractVasible = false;
            this.arrearageVasible = false;
          });
        }
      })

    },
    handlePreview (file) {
      if (file && file.raw) {
        let formData = new FormData();
        formData.append("file", file.raw);
        this.$request.HttpPost("/system/upload/upImg", formData).then((res) => {
          this.ruleForm.over_voucher.push(res.data.fileInfo.hostUrl);
        });
      }
    },
    // termination() {
    //   this.$myconfirm("此操作将终止该合同, 是否继续?", "提示").then(() => {
    //     this.$request
    //       .HttpPost(`/contract/stopContract`, {
    //         contract_id: this.id
    //       })
    //       .then((res) => {
    //         this.$common.notifySuccess(res.msg);
    //         this.$router.go(-1);
    //       });
    //   });
    // },
    getMerchantInfo (id) {
      console.log(id);
      this.$request
        .HttpGet("/merchants/merchantsBillInfo", {
          merchants_id: id,
        })
        .then((res) => {
          this.merchantInfo = res.data;
        });
    },
    getDetail () {
      let id = this.$route.query.id;
      this.$request.HttpGet(`/contract/edit?id=${id}`).then((res) => {
        if (res.data) {
          res.data.contract_increasing = res.data.contract_increasing
            ? JSON.parse(res.data.contract_increasing)
            : [];
          let temp = [];
          let payFeesItem = [];
          let index = 0;
          res.data.contract_increasing.forEach((item, i) => {
            item.fees_list.forEach((list, j) => {
              payFeesItem = payFeesItem.concat(
                list.contract_rentInfo.map((item) => ({
                  ...item,
                  contract_fee_name: list.name,
                }))
              );
              if (j == 0) {
                index += 1;
                temp.push({
                  item: {
                    ...list,
                  },
                  ...item,
                  rowspan: item.fees_list.length,
                  index,
                });
              } else {
                temp.push({
                  item: {
                    ...list,
                  },
                  ...item,
                });
              }
            });
          });
          res.data.contract_increasing_temp = temp;
          // this.rateList(res.data.contract_increasing);
          res.data.merchants_stall &&
            res.data.merchants_stall.forEach((item) => {
              if (res.data.contract_fee_setting) {
                res.data.contract_fee_setting.forEach((list) => {
                  if (item.merchants_stall_id == list[0].merchants_stall_id) {
                    list[0].merchants_stall_name = item.merchants_stall_name;
                  }
                });
              }
            });
          res.data.contract_rent_info = res.data.contract_rent_info ? JSON.parse(res.data.contract_rent_info) : [];
          // res.data.payList = [].concat(
          //   res.data.contract_rent_info,
          //   res.data.dcList
          // );
          res.data.payList = [].concat(payFeesItem, res.data.dcList).filter(Boolean);

          res.data.approvalList = [{
            worker_name: res.data.worker_name,
            worker_id: res.data.worker_id,
            agree_worker_name: res.data.agree_worker_name,
            agree_worker_id: res.data.agree_worker_id,
            contract_status_name: res.data.contract_status_name,
            contract_desc: res.data.contract_desc,
            create_at: res.data.update_at,
          }];
          this.detail = res.data;
          this.getBillList();
          // console.log(this.detail.contract_increasing);
        }
      });
    },
    sendMessage () {
      this.$request
        .HttpPost("/contract/sendContractRenewal", this.renewFormmessage)
        .then(() => {
          this.$common.notifySuccess("发送成功");
          this.renewVasible = false;
        });
    },
    close () {
      this.renewFormmessage.content = "";
      this.renewFormmessage.contract_id = "";
    },
    seedNotification () {
      this.$request
        .HttpGet("/setting/edit", {
          key: "contractWarningSetting",
        })
        .then((res) => {
          let str = res.data.values.content;
          str = str
            .replace("“商户姓名”", this.detail.merchants_stall_name)
            .replace("“合同编号”", this.detail.contract_no)
            .replace("“天数”", res.data.values.surplus_day);
          this.renewFormmessage.content = str;
          this.renewFormmessage.contract_id = this.detail.contract_id;
          this.renewVasible = true;
        });
    },
    renew () {
      this.$request
        .HttpPost("/contract/checkHaveChildContract ", {
          contract_id: this.id,
        })
        .then((res) => {
          if (res.data) {
            this.$common.notifyError("已有续签合同正在审核,请勿重复续签");
          } else {
            this.goUrl("renew");
          }
        });
    },
    goUrl (type) {
      this.$router.push({
        path: `/contract/addedcontract?id=${this.detail.contract_id}&type=${type}`,
      });
    },
    checktpl (url) {
      console.log(url);
      if (url) {
        let isUrl = url.search(/https:\/\/|http:\/\//);
        if (isUrl == -1) {
          window.open(process.env.VUE_APP_LOGIN + "/" + url);
        } else {
          window.open(url);
        }
      } else {
        this.$message("该合同没有上传合同文件！");
      }
    },
    // 递增费用
    rateList (data) {
      if (!Array.isArray(data)) return;
      let arr = data[0] && data[0].fees_list;
      data.forEach((item) => {
        if (!item.change) {
          item.change = 1;
        } else {
          item.change = Number(item.change);
        }
        item.feeName = [];
        item.ratehMoney = [];
        if (item.fees_list) {
          item.fees_list.forEach((items) => {
            item.ratehMoney.push(items.pay_price);
            arr.forEach((its) => {
              if (its.id == items.id && items.pay_price != its.pay_price) {
                item.feeName.push(items.name);
              }
            });
          });
        }
      });
    },
    checkvoucher () {
      this.voucher = [];
      let coupon_voucher = this.detail.contract_agree.over_voucher;
      if (!coupon_voucher)
        return this.$message({ message: "暂无凭证", type: "warning" });
      try {
        this.voucher = JSON.parse(coupon_voucher);
      } catch {
        this.voucher = [coupon_voucher];
      }
      if (this.voucher.length == 0) {
        return this.$message({ message: "暂无凭证", type: "warning" });
      }
      this.vouchervisible = true;
    },
    getFixedList (id) {
      this.$request
        .HttpGet("/merchants/merchantsBillFixedList", {
          merchants_id: id,
        })
        .then((res) => {
          this.fixedList = res.data || [];
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.increase {
  width: 100%;
  overflow: auto;
}

.customTable {
  width: 100%;

  tr {
    // display: flex;
    border-bottom: 1px solid #ebeef5;
    background-color: #ffffff;
    font-size: 14px;

    td,
    th {
      // flex-shrink: 0;
      text-align: center;
      height: 37px;
      line-height: 37px;
      color: #606266;
      white-space: nowrap;
      min-width: 150px;
    }

    th {
      background: rgb(245, 245, 245);
      color: #909399;
      display: table-cell;
    }
  }
}

/deep/ .el-table .el-table__cell {
  padding: 0;
}

.detail-box {
  // margin-top: 20px;
  display: flex;
  // background-color: red;
}

.detail-left {
  // width: 720px;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dedede;
  // box-sizing: border-box;
  overflow-y: auto;

  .title {
    padding: 19px 20px;
    box-sizing: border-box;
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  .userinfo {
    display: flex;
    align-items: flex-end;
    width: 680px;
    padding-left: 30px;
    box-sizing: border-box;
    margin: 0 auto;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;

    .user {
      display: flex;
      align-items: flex-end;
      width: 250px;

      div {
        margin-left: 28px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }

    .head {
      width: 80px;
      height: 80px;
    }

    .item {
      &:nth-child(2) {
        width: 200px;
      }

      div {
        &:nth-child(2) {
          margin-top: 14px;
        }

        span {
          margin-top: 14px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        }

        .status {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #58b674;
          cursor: pointer;

          .icon {
            margin-left: 5px;
          }
        }
      }
    }
  }

  // shoplist
  .shoplist {
    width: 100%;
    // height: 329px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    margin: 30px auto;
    // padding-top: 12px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    box-sizing: border-box;

    .listbox {
      height: 270px;
      overflow: auto;

      .list {
        display: flex;
        padding: 40px 0 10px 60px;
        border-bottom: 1px solid #f5f5f5;

        .listLeft {
          width: 14px;
          margin-right: 30px;

          img {
            width: 14px;
            height: 14px;
          }
        }

        .right {
          display: flex;
          flex-wrap: wrap;

          .listItem {
            margin-bottom: 30px;
            display: flex;

            // &:nth-child(4n) {
            //   width: 160px;
            // }
            .content {
              width: 190px;
            }

            .num {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .count {
      height: 62px;
      // text-align: right;
      padding-right: 67px;
      padding-top: 31px;
      box-sizing: border-box;
      border-top: 1px solid #f5f5f5;
      display: flex;
      justify-content: flex-end;

      div {
        &:nth-child(1) {
          margin-right: 40px;
        }

        span {
          &:nth-child(1) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }

          &:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bolder;
            color: #e90000;
          }
        }
      }

      // justify-content: flex-end;
    }
  }

  .moneytype {
    width: 100%;
    margin: 30px auto;
    padding-left: 30px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b5b5b5;
    overflow: hidden;

    .item {
      min-height: 30px;
      display: flex;
    }

    .bold {
      font-weight: bold;
      color: #000000;
      margin-right: 9px;
    }

    .margin {
      margin-right: 9px;
    }

    .name {
      display: inline-block;
      width: 60px;
      text-align: right;
      margin-right: 17px;
      color: #000000;
    }
  }
}

// right
.detail-right {
  width: 70%;
  height: 100%;
  margin-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
  }

  .title {
    // padding: 19px 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-bottom: 29px;
  }

  .spxx {
    height: 157px;
    background: #ffffff;
    border: 1px solid #dedede;
    // padding
  }

  .ydjfxx {
    // min-height: 272px;
    flex: 1;
    background: #ffffff;
    border: 1px solid #dedede;
    margin-top: 21px;
    overflow: hidden;

    .termination {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: flex-start;
      width: 100%;
      height: 100%;

      .boxList {
        width: 50%;
        height: 40px;
        line-height: 40px;

        span:first-child {
          display: inline-block;
          width: 120px;
          text-align: right;
        }
      }
    }
  }

  .htgl {
    height: 128px;
    border: 1px solid #dedede;
    margin-top: 20px;

    .send {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #3d7eff;
      margin-left: 19px;
      cursor: pointer;
    }

    .item {
      .btn {
        width: 188px;
        height: 36px;
        background: #f5f5f5;
        border: 1px solid #666666;
        margin-left: 26px;

        &:first-child {
          margin-left: 45px;
        }
      }
    }
  }

  // fk
  .fk {
    //  height:auto;
    background: #ffffff;
    border: 1px solid #dedede;
    margin-top: 20px;
    padding: 0;

    .title {
      padding: 20px 0 0 20px;
      margin-bottom: 19px;
      box-sizing: border-box;
    }

    .time {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      opacity: 0.6;
      margin-left: 20px;
    }

    .list {
      height: 100px;
      overflow: auto;

      img {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }

      .main {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        margin-right: 9px;
      }

      .tips {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        opacity: 0.6;
      }

      .godetail {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3d7eff;
        margin-left: 437px;
        cursor: pointer;
      }

      .listitem {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .listitem:hover {
        background-color: #f9f9f9;
      }
    }
  }
}

.imgBox {
  display: flex;

  .imgList {
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 110px;
    height: 110px;

    i {
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      width: 110px;
      height: 110px;
    }
  }
}

.top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 0;
  margin-top: 20px;
  border: 1px solid #dedede;

  .title {
    padding: 20px;
    box-sizing: border-box;
  }

  .lefts-top {
    width: 100%;
    height: 250px;
    box-sizing: border-box;
    overflow: hidden;

    .list {
      display: flex;
      align-items: center;
      margin-top: 15px;
      padding-left: 40px;
      box-sizing: border-box;

      .item {
        &:first-child {
          width: 250px;
        }

        &:nth-child(2) {
          width: 150px;
        }

        span {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          white-space: nowrap;

          &:nth-child(1) {
            color: #999999;
            width: 150px;
          }

          &:nth-child(2) {
            color: #000000;
          }

          img {
            width: 14px;
            height: 14px;
          }
        }

        .ye {
          font-size: 16px !important;

          font-weight: bold;
          color: #02ce80 !important;

          span {
            font-size: 12px;
          }
        }

        .qf {
          // width: 120px;
          white-space: nowrap;
          font-size: 16px !important;
          font-weight: bold;
          color: #f8c405 !important;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .lefts-bottom {
    width: 70%;
    margin-left: 20px;
    box-sizing: border-box;
    height: 250px;
    // margin-left: 20px;
    box-sizing: border-box;
    // overflow: hidden;
    overflow-y: auto;
  }
}

.stopmessage {
  margin-top: 20px;
  padding: 20px;
  //  height: 600px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #dedede;

  .title {
    margin-bottom: 20px;
  }
}

/deep/ .el-carousel__container {
  height: 570px;

  .voucher-img {
    height: 100%;
    object-fit: contain;
  }
}
</style>
